import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CreateTicket } from "./CreateTicket";

export const SupportHeader = () => {
    const [open, setOpen] = useState(false);

    const modalControll = () => {
        setOpen(!open);
    };
    return (
        <>
            <div className="w-full flex justify-between flex-col md:flex-row">
                <h3 className="mt-2 text-[20px] text-gray-600">Support Tickets</h3>
                <button
                    className="mt-2 flex items-center max-w-[200px] gap-x-[10px] h-[40px] px-[12px] special_profile_button text-[14px] lg:text-[16px]"
                    onClick={modalControll}
                >
                    <AiOutlinePlus />
                    Create New Ticket
                </button>
            </div>
            {open && <CreateTicket modalControll={modalControll} />}
        </>
    );
};

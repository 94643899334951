/* eslint-disable react-hooks/exhaustive-deps */
// Configuration
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Third party package
import { useDispatch, useSelector } from "react-redux";
import { setPointTax } from "../../../../../Redux/features/checkout/billingSummaryPointSlice";
import { setTax } from "../../../../../Redux/features/checkout/billingSummarySlice";
import {
  calculatePercentage,
  getBillingInfoRenderingDecision,
} from "../../../../../assets/utilities/CheckoutHelperCalculation/checkoutHelperCalculation";
import { OrderPlacement } from "./OrderPlacement";
import { useGetProfileDetailsWIthAuthQuery } from "../../../../../Redux/features/userInfo/userApi";
import { useGetBundleByIdQuery } from "../../../../../Redux/features/bundle/bundleApi";

const BillingSummary = () => {
  const { id } = useParams();
  const { data: bundleData } = useGetBundleByIdQuery(id);
  const [subTotalCalculated, setSubTotalCalculated] = useState(0);
  const dispatch = useDispatch();
  // payment method innformationn check
  const paymentMethod = useSelector(
    (state) => state.persistedReducer.checkoutDetailes?.paymentMethod
  );

  // billing innfo
  const billingSummaryDetailes = useSelector(
    (state) => state.persistedReducer?.billingSummary?.billingSummary
  );
  const billingSummaryDetailesWithPoint = useSelector(
    (state) =>
      state.persistedReducer.billingSummaryPoint.billingSummaryWithPoint
  );

  // redux api call
  const { data } = useGetProfileDetailsWIthAuthQuery();
  const packageDiscount =
    data?.userMatrimonyPackageInfo?.package?.shoppingDiscount;

  useEffect(() => {
    const { subTotal } = getBillingInfoRenderingDecision(
      paymentMethod,
      billingSummaryDetailes,
      billingSummaryDetailesWithPoint
    );
    setSubTotalCalculated(subTotal);
  }, [
    paymentMethod,
    billingSummaryDetailes,
    billingSummaryDetailesWithPoint,
    packageDiscount,
    data,
  ]);

  const bundlePrice =
    paymentMethod === "amarPay"
      ? bundleData?.data?.price
      : bundleData?.data?.priceInPoint;

  useEffect(() => {
    if (paymentMethod === "amarPay" || paymentMethod === "cash") {
      dispatch(setTax(calculatePercentage(subTotalCalculated, 15)));
    } else if (paymentMethod === "point") {
      dispatch(setPointTax(calculatePercentage(subTotalCalculated, 15)));
    }
  }, [subTotalCalculated, dispatch]);

  return (
    <div className="p-5 bg-gray-50">
      <h3 className="font-bold mb-8">Billing Summary</h3>
      <div className="pb-7 border-b border-[#E0E0E0]">
        <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5 mb-3">
          <p>Bundle Price</p>
          <p>{bundlePrice}</p>
        </div>
        <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5 mb-3">
          <p>Shipping</p>
          <p>{100}</p>
        </div>
      </div>
      <div>
        <div className="flex justify-between items-center font-bold text-sm leading-5 my-5">
          <p>Total</p>
          <p>BDT {Number(bundlePrice + 100)}</p>
        </div>
        <OrderPlacement
          grandTotal={Number(bundlePrice + 100)}
          paymentMethod={paymentMethod}
        />
      </div>
    </div>
  );
};

export default BillingSummary;

import React, { useEffect, useState } from "react";

// Components
import ProductImagesCard from "./ProductImagesCard";

const SingleProductImages = ({ product }) => {
    const [selectedImage, setSelectedImage] = useState("");
    const { photos } = product || {};

    useEffect(() => {
        setSelectedImage(photos[0]?.url);
    }, [photos]);
    return (
        <div>
            <div className="flex gap-6 flex-col-reverse lg:flex-row">
                <div>
                    <ProductImagesCard {...{ setSelectedImage, selectedImage, photos }} />
                </div>
                <div>
                    <img className=" w-full lg:w-[547px] h-[374px] lg:h-[494px] rounded-md" src={selectedImage} alt="" />
                </div>
            </div>
        </div>
    );
};

export default SingleProductImages;

import getCookie from "../../../Helper/cookies/getCookie";
import apiSlice from "../../api/apiSlice";

export const packageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPackages: builder.query({
      query: () => ({
        url: `/package/shop/get-all-packages`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
    buyFreePackage: builder.mutation({
      query: ({ packageId, user, numberOfDay }) => ({
        url: `/package/shop/buy-package/callback?packageId=${packageId}&_user=${user}&numberOfDay=${numberOfDay}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
    buyPremiumPackage: builder.mutation({
      query: (data) => ({
        url: `/package/shop/buy-package`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
      }),
    }),
    buyPackageByPoint: builder.mutation({
      query: (data) => ({
        url: `/package/shop/buy-package-by-point`,
        method: "POST",
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
      }),
    }),
  }),
});

export const {
    useGetAllPackagesQuery,
    useBuyFreePackageMutation, useBuyPremiumPackageMutation,useBuyPackageByPointMutation
} = packageApi;

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // borderBottom: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //     borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const faqData = [
    {
      id: 1,
      question: "What is your return policy?",
      answer:
        "Our return policy allows customers to return items within 30 days of purchase for a full refund or exchange. The item must be unused and in its original packaging.",
      panel: "panel1",
    },
    {
      id: 2,
      question: "How long does shipping take?",
      answer:
        "Shipping times vary depending on the product and your location. Most orders are shipped within 2-3 business days and take an additional 3-5 business days for delivery.",
      panel: "panel2",
    },
    {
      id: 3,
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and Apple Pay.",
      panel: "panel3",
    },
  ];

  return (
    <div className="w-full">
      <div className="max-w-[1200px] mx-auto mt-[20px]">
        {faqData?.map((item) => {
          return (
            <Accordion
              key={item?.id}
              expanded={expanded === item?.panel}
              onChange={handleChange(item?.panel)}
            >
              <AccordionSummary
                aria-controls={`${item?.panel}d-content`}
                id={`${item?.panel}d-header`}
              >
                <Typography>{item?.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item?.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

import React from "react";

// Third party packgages
import { TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const OtherDetailsCompareCard = ({ product }) => {
    const navigate = useNavigate();
    const { name, price, photos, _id } = product || {};

    return (
        <div className="rounded-xl w-[304px] shadow-md cursor-pointer" onClick={() => navigate(`/shop/singleProduct/${_id}`)}>
            <img className="w-full h-[198px] rounded-t-xl" src={photos[0]?.url} alt="productPhoto" />
            <div className="p-6 z-50">
                <div className="flex p-2 bg-[#E41272] rounded-[100px] text-white max-w-[58px]">
                    <TbCurrencyTaka className="text-xs" />
                    <p className="text-xs">{price}</p>
                </div>
                <p className="mt-2 text-[#272727] text-[16px] leading-6">{name}</p>
            </div>
        </div>
    );
};

export default OtherDetailsCompareCard;

import React from "react";
import img2 from "../../../../assets/images/landingBanner/ProductImages/basketball.png";
import img3 from "../../../../assets/images/landingBanner/ProductImages/earbuds.png";
import img1 from "../../../../assets/images/landingBanner/ProductImages/sweater.png";
import { useNavigate } from "react-router-dom";

export const DiscoverNewItems = () => {
    const navigate = useNavigate();
    const data = [
        {
            id: 1,
            image: img1,
        },
        {
            id: 2,
            image: img2,
        },
        {
            id: 3,
            image: img3,
        },
    ];
    return (
        <div className="max-w-[1270px] mx-auto px-[16px] mt-[40px]">
            <section className="flex">
                <h3 className="font-outfit text-[22px]">
                    <span className="font-semibold text-gray-700">Discover more</span>{" "}
                    <span className="text-gray-400 font-semibold">Good things</span>
                </h3>
            </section>
            <section className="mt-[20px] grid grid-cols-1 md:grid-cols-3 gap-[30px]">
                {data?.map(item => {
                    return (
                        <div
                            key={item?.id}
                            className={`flex items-center w-full md:w-[300px] p-[20px] ${item.id === 1 && "bg-orange-100"} ${
                                item?.id === 2 && "bg-lime-100"
                            } ${item.id === 3 && "bg-sky-100"}`}
                        >
                            <div className="flex flex-col items-start gap-y-[10px]">
                                <p className="text-[10px]">Explore new arrivals</p>
                                <h3 className="text-[20px] font-medium">Give the gift of choice</h3>
                                <button onClick={()=>navigate("/shop")} className="special_profile_button_hover">
                                    Show me all
                                </button>
                            </div>
                            <div>
                                <img className="w-[140px]" src={item?.image} alt="product" />
                            </div>
                        </div>
                    );
                })}
            </section>
        </div>
    );
};

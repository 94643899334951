import React from "react";
import customFunction from "../../../assets/utilities/CustomFunction/customFunction";

const Image = ({ photo, height, width, onClick, rounded }) => {
    // profile photo handler
    const { profilePhotoDecisionMaker } = customFunction;
    return (
        <div
            onClick={onClick}
            className={`${height} ${width} rounded-[${rounded}] bg-cover bg-center cursor-pointer overflow-hidden`}
            style={{
                backgroundImage: `url(${profilePhotoDecisionMaker(photo)})`,
            }}
        ></div>
    );
};

export default Image;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import getCookie from "../../../../Helper/cookies/getCookie";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";
import PackageForm from "../../Packages/PackageForm";
import { PackagePriceTest } from "./PackagePriceTest";

export const PackageCard = ({ pack, packages, index, height, setSuccessSnackBarOpen, setSelectedPack, useCase, hovered, setHovered }) => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    // function declaration
    const handleBuyPack = () => {
        if (getCookie("token")) {
            if (useCase === "home") {
                return setSelectedPack(pack);
            }
            return setShowPopup(true);
        } else {
            navigate("/login");
        }
    };

    const handleHovered = indexNum => {
        setHovered(indexNum);
    };
    return (
        <VarientAnimation direction="up" delay={0.3}>
            <div
                className={`${height ? height : "h-[580px]"} w-full md:w-[320px] rounded-[10px] px-[30px] py-[34px] bg-[rgb(255,255,255)] group ${
                    hovered === index && "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]"
                } duration-500 shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px] relative`}
                onMouseEnter={() => handleHovered(index)}
                onMouseLeave={() => handleHovered(0)}
            >
                <PackagePriceTest pack={pack} hovered={hovered} index={index} />
                <div className="mt-[20px] ">
                    <ul className={`list-disc pl-6 text-[15px] font-george h-72 ${hovered === index ? "text-[#FFFFF9]" : "text-gray-400"}`}>
                        {pack?.type?.toLowerCase()?.includes("free") && (
                            <>
                                <li>Register/Login To Shop Feature</li>
                                <li>Browse Shop</li>
                                <li>Access All Features</li>
                                <li>Buy products, Track Order</li>
                            </>
                        )}
                        {pack?.name?.toLowerCase()?.includes("priority") && (
                            <>
                                <li>All Feature of {packages[index - 1]?.name}</li>
                                <li>Special Discount on Purchase</li>
                                <li>Priority Point on Purchase</li>
                            </>
                        )}
                        {pack?.name?.toLowerCase()?.includes("bundle") && (
                            <>
                                <li>All Feature of {packages[index - 1]?.name}</li>
                                <li>Special shop bundles such as Marriage Shopping, Haldi Shopping, etc</li>
                            </>
                        )}
                    </ul>
                </div>
                <div className="absolute w-full bottom-0 left-0 mb-[34px] px-[34px]">
                    {!pack?.name?.toLowerCase()?.includes("free") && (
                        <button
                            className="w-full rounded-[4px] bg-black group-hover:bg-white group-hover:text-primary duration-300 ease-in-out text-white py-[6px]"
                            onClick={handleBuyPack}
                        >
                            Buy Now
                        </button>
                    )}
                    {pack?.name?.toLowerCase()?.includes("free") && (
                        <button
                            className={`w-full rounded-[4px] ${hovered === index ? "text-primary" : "text-white"} ${
                                hovered === index ? "bg-white" : "bg-black"
                            } duration-300 ease-in-out py-[6px]`}
                            disabled
                        >
                            Try Free
                        </button>
                    )}
                </div>
            </div>
            {showPopup && useCase !== "home" && <PackageForm {...{ pack, setShowPopup, setSuccessSnackBarOpen }} />}
        </VarientAnimation>
    );
};

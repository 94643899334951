import React from "react";

// componets
import { FilterContainer } from "./FilterContainer";
import BundleContainer from "./bundle/BundleContainer";

//css
import "../../../components/shared/NavBar/TopMenu.css";
import { MainLayout } from "../../shared/Lawout/MainLayout";

export const ShopContainer = () => {
    return (
        <MainLayout title="Shop">
            <BundleContainer />
            <FilterContainer />
        </MainLayout>
    );
};

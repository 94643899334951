/* eslint-disable react-hooks/exhaustive-deps */
// Configuration
import React, { useEffect, useState } from "react";

// Third party package
import { useDispatch, useSelector } from "react-redux";
import { setPointTax } from "../../../../../Redux/features/checkout/billingSummaryPointSlice";
import { setTax } from "../../../../../Redux/features/checkout/billingSummarySlice";
import {
    calculatePercentage,
    getBillingInfoRenderingDecision,
} from "../../../../../assets/utilities/CheckoutHelperCalculation/checkoutHelperCalculation";
import { OrderPlacement } from "./OrderPlacement";
import { useGetProfileDetailsWIthAuthQuery } from "../../../../../Redux/features/userInfo/userApi";

const BillingSummary = () => {
    const [subTotalCalculated, setSubTotalCalculated] = useState(0);
    const [calculatedDiscount, setCalculatedDiscount] = useState(0);
    const [calculatedTax, setCalculatedTax] = useState(0);
    const dispatch = useDispatch();
    // payment method innformationn check
    const paymentMethod = useSelector(state => state.persistedReducer.checkoutDetailes?.paymentMethod);

    // billing innfo
    const billingSummaryDetailes = useSelector(state => state.persistedReducer?.billingSummary?.billingSummary);
    const billingSummaryDetailesWithPoint = useSelector(state => state.persistedReducer.billingSummaryPoint.billingSummaryWithPoint);
    
    // redux api call 
    const { data } = useGetProfileDetailsWIthAuthQuery();
    const packageDiscount = data?.userMatrimonyPackageInfo?.package?.shoppingDiscount;
    const shopPackageDiscount = data?.shopPackage?.package?.discount;


    useEffect(() => {
        const { discount, subTotal, tax } = getBillingInfoRenderingDecision(paymentMethod, billingSummaryDetailes, billingSummaryDetailesWithPoint);
        setSubTotalCalculated(subTotal);
        setCalculatedDiscount(discount + (subTotal * packageDiscount / 100 || 0) + (subTotal * shopPackageDiscount / 100 || 0));
        setCalculatedTax(tax);
    }, [paymentMethod, billingSummaryDetailes, billingSummaryDetailesWithPoint,packageDiscount,data]);

    useEffect(() => {
        if (paymentMethod === "amarPay" || paymentMethod === "cash") {
            dispatch(setTax(calculatePercentage(subTotalCalculated, 15)));
        } else if (paymentMethod === "point") {
            dispatch(setPointTax(calculatePercentage(subTotalCalculated, 15)));
        }
    }, [subTotalCalculated, dispatch]);

    return (
        <div className="p-5 bg-gray-50">
            <h3 className="font-bold mb-8">Billing Summary</h3>
            <div className="pb-7 border-b border-[#E0E0E0]">
                <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5 mb-3">
                    <p>Subtotal</p>
                    <p>{subTotalCalculated}</p>
                </div>
                {calculatedDiscount !==0 && <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5 mb-3">
                    <p>Discount</p>
                    <p>{calculatedDiscount}</p>
                </div>}
                <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5 mb-3">
                    <p>Shipping</p>
                    <p>{100}</p>
                </div>
                <div className="flex justify-between items-center text-sm leading-5 text-[#4F4F4F] pr-5">
                    <p>Tax</p>
                    <p>{calculatePercentage(subTotalCalculated, 15)}</p>
                </div>
            </div>
            <div>
                <div className="flex justify-between items-center font-bold text-sm leading-5 my-5">
                    <p>Grand Total</p>
                    <p>BDT {Number(subTotalCalculated + 100 + calculatedTax)}</p>
                </div>
                <OrderPlacement grandTotal={Number(subTotalCalculated + 100 + calculatedTax)} discount={calculatedDiscount} paymentMethod={paymentMethod} />
            </div>
        </div>
    );
};

export default BillingSummary;

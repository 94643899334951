import React from "react";

//Icons
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SecurityIcon from "@mui/icons-material/Security";
import { RiSecurePaymentLine } from "react-icons/ri";

export const ServiceHighlight = () => {
    return (
        <div className="max-w-[1200px] mx-auto mt-[100px] grid grid-cols-1 md:grid-cols-3 gap-[20px] md:grid-rows-2 px-[16px]">
            <div className="mx-auto flex flex-col justify-center items-center rounded-[4px] gap-y-[10px] md:row-span-2 h-[200px] px-[20px] w-full bg-primary">
                <div className="bg-white h-[50px] w-[50px] flex justify-center items-center rounded-full">
                    <LocalShippingIcon />
                </div>
                <h3 className="font-outfit text-white">Super Fast and Free Delivery</h3>
            </div>
            <div className="mx-auto flex flex-col md:flex-row justify-center items-center rounded-[4px] bg-primary w-full gap-y-[10px] md:row-span-1 h-[200px] md:h-auto">
                <div className="bg-white h-[50px] w-[50px] flex justify-center items-center rounded-full mr-[15px]">
                    <SecurityIcon />
                </div>
                <h3 className="font-outfit text-white">Non-contact Shipping</h3>
            </div>
            <div className="mx-auto flex flex-col justify-center items-center rounded-[4px] bg-primary  gap-y-[10px] md:row-span-2 px-[20px] w-full h-[200px] md:h-auto">
                <div className="bg-white h-[50px] w-[50px] flex justify-center items-center rounded-full">
                    <HandshakeIcon />
                </div>
                <h3 className="font-outfit text-white">Money-back Guaranteed</h3>
            </div>
            <div className="mx-auto flex justify-center items-center rounded-[4px] bg-primary w-full gap-y-[10px] md:row-span-1 flex-col md:flex-row h-[200px] md:h-auto">
                <div className="bg-white h-[50px] w-[50px] flex justify-center items-center rounded-full mr-[15px]">
                    <RiSecurePaymentLine className="text-[22px]" />
                </div>
                <h3 className="font-outfit text-white">Super Secure Payment System</h3>
            </div>
        </div>
    );
};

import React from "react";
import { DesktopNotification } from "../DesktopNotification";
import { NotificationForMobile } from "../NotificationForMobile";

export const NotificationContainer = () => {
    return (
        <section>
            <div className="hidden lg:block">
                <DesktopNotification />
            </div>
            <div className="lg:hidden">
                <NotificationForMobile />
            </div>
        </section>
    );
};

import React from "react";
import { useParams } from "react-router";

// Third party packages
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

// Components
// import { useDispatch } from "react-redux";
// import { setInitSubTotalWithPoint } from "../../../../../Redux/features/checkout/billingSummaryPointSlice";
// import { initialSubTotal, setInitialOrderItems } from "../../../../../Redux/features/checkout/billingSummarySlice";
// import { subPricesWithPoint, sumPrices } from "../../../../../assets/utilities/CheckoutHelperCalculation/checkoutHelperCalculation";
// import OrderReviewCard from "../../../../shared/Cards/Shop/OrderReviewCard/OrderReviewCard";
import { useGetBundleByIdQuery } from "../../../../../Redux/features/bundle/bundleApi";
import { useSelector } from "react-redux";

const OrderReview = () => {
  const { id } = useParams();
  const { data } = useGetBundleByIdQuery(id);
  const { price, priceInPoint, image, name } = data?.data || {};
  const paymentMethod = useSelector(
    (state) => state.persistedReducer.checkoutDetailes?.paymentMethod
  );
  const { Panel } = Collapse;
  const panelHeader = (
    <div>
      <h3 className="leading-5 font-bold mb-1">Order Review</h3>
    </div>
  );

  let content;
  if (!data) {
    content = (
      <div className="font-Poppins text-gray-400">
        Your cart is currently empty.
      </div>
    );
  } else {
    content = (
      <div>
        <div className="flex items-center gap-3 w-full pb-5 border-b border-[#E0E0E0] mb-4">
          <div className="w-28">
            <img className="w-20 h-20 rounded border" src={image} alt="" />
          </div>
          <div className="w-full">
            <div className="flex justify-between items-start mb-3">
              <p className="max-w-[156px] text-xs leading-5">{name}</p>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-center">
                <p className="leading-5 font-semibold text-base">
                  {paymentMethod==="amarPay"? price:priceInPoint}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition="end"
        >
          <Panel header={panelHeader} key="1">
            {content}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default OrderReview;

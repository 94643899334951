import React from "react";

//components
import headphone from "../../../../assets/images/landingBanner/headphone.png";
import { useNavigate } from "react-router-dom";
export const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[1300px] mx-auto px-[16px] lg:mt-[100px]">
      <div className="h-[280px] w-full flex items-center justify-evenly bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] px-[10px]">
        <div className="content">
          <h3 className="text-white text-[22px] lg:text-[32px] lg:w-[350px] text-left">
            Grab Upto 50% Off On Selected Headphonne
          </h3>
          <button
            onClick={() => navigate("/shop")}
            className="px-4 py-1 rounded-[50px] mt-[15px] cursor-pointer special_profile_button_hover bg-transparent text-white"
          >
            Buy Now
          </button>
        </div>
        <div>
          <img
            src={headphone}
            className="h-[180px] flex-initial lg:h-[250px] w-[300px] lg:w-full"
            alt="girlListenMusic"
          />
        </div>
      </div>
    </div>
  );
};

// Configuration
import React, { useState } from "react";

// Third party package
import { Input } from "antd";

// components
import { AllProducts } from "./AllProducts/AllProducts";
import { CategorySelector } from "./Filter/CategorySelector";
import { PriceRangeSelector } from "./Filter/PriceRangeSelector";

//css
import { useDispatch, useSelector } from "react-redux";
import "../../../App.css";
import { setSearchTerm } from "../../../Redux/features/Shop/shopFilterSliceV3";
import { MobileFilterModal } from "./MobileFilterModal";

export const FilterContainer = ({limit}) => {
    const [isOpen, setOpen] = useState(false);
    const { search } = useSelector(state => state.persistedReducer.shopFilter);
    const dispatch = useDispatch();

    const modalControll = () => {
        setOpen(!isOpen);
    };

    const handleSearchTerm = searchTerm => {
        dispatch(setSearchTerm(searchTerm));
    };
    return (
        <>
            <div className="max-w-[1200px] mx-auto mt-[12px] lg:flex gap-x-[20px]">
                <section className="lg:flex flex-col items-center gap-x-[100px] w-[200px] hidden">
                    <div>
                        <Input placeholder="Search" value={search} className="w-[200px]" onChange={e => handleSearchTerm(e.target.value)} />
                    </div>
                    <CategorySelector />
                    <PriceRangeSelector />
                </section>
                {/* Mobile Version Filter */}
                <div className="px-[16px] lg:hidden">
                    <button
                        className="special_profile_button text-white my-[10px] w-full h-[40px] rounded-[4px]"
                        onClick={modalControll}
                    >
                        Filter
                    </button>
                </div>
                <section className="flex-1 px-[16px]">
                    <AllProducts {...{limit}} />
                </section>
            </div>
            {isOpen && <MobileFilterModal modalControll={modalControll} />}
        </>
    );
};

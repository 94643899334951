import React, { useEffect, useState } from "react";

// Third party packages
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

// Components
import { useDispatch } from "react-redux";
import { setInitSubTotalWithPoint } from "../../../../../Redux/features/checkout/billingSummaryPointSlice";
import { initialSubTotal, setInitialOrderItems } from "../../../../../Redux/features/checkout/billingSummarySlice";
import VarientAnimation from "../../../../../assets/utilities/Animation/VarientAnimation";
import { subPricesWithPoint, sumPrices } from "../../../../../assets/utilities/CheckoutHelperCalculation/checkoutHelperCalculation";
import OrderReviewCard from "../../../../shared/Cards/Shop/OrderReviewCard/OrderReviewCard";

const OrderReview = () => {
    const dispatch = useDispatch();
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);

    useEffect(() => {
        // array processing
        let orderItems = [];
        cart?.map(item => {
            const data = {
                product: item?._id,
                quantity: 1,
            };
            orderItems.push(data);
            return data;
        });

        dispatch(initialSubTotal(sumPrices(cart)));
        dispatch(setInitSubTotalWithPoint(subPricesWithPoint(cart)));
        dispatch(setInitialOrderItems(orderItems));
    }, [dispatch, cart]);

    const { Panel } = Collapse;
    const panelHeader = (
        <div>
            <h3 className="leading-5 font-bold mb-1">Order Review</h3>
            <p className="text-xs leading-5">{cart?.length} items in cart</p>
        </div>
    );

    let content;
    if (!cart || cart?.length <= 0) {
        content = <div className="font-Poppins text-gray-400">Your cart is currently empty.</div>;
    } else {
        content = (
            <div className="overflow-y-auto h-[400px] pr-1" id="myScrollbar">
                {cart?.map(product => (
                    <OrderReviewCard key={product._id} {...{ product, cart, setCart }} />
                ))}
            </div>
        );
    }

    return (
        <div>
            <VarientAnimation direction="left" delay={0.3}>
                <div>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={["1"]}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        expandIconPosition="end"
                    >
                        <Panel header={panelHeader} key="1">
                            {content}
                        </Panel>
                    </Collapse>
                </div>
            </VarientAnimation>
        </div>
    );
};

export default OrderReview;

// Configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { HiOutlineGlobe, HiTag } from "react-icons/hi";
import { TbCreditCard, TbCurrencyTaka } from "react-icons/tb";

// Third party packages
import Rating from "@mui/material/Rating";

// Components
import { useDispatch } from "react-redux";
import { useGetAllReviewsByProductIDQuery } from "../../../../../Redux/features/Shop/shopApi";
import { increaseCartCount, increaseWishlistCount } from "../../../../../Redux/features/Shop/shopSlice";
import customFunction from "../../../../../assets/utilities/CustomFunction/customFunction";
import isAlreadyProductinCart from "../../../../../assets/utilities/isAlreadyProductinCart/isAlreadyProductInCart";
import isWishlisted from "../../../../../assets/utilities/isWishlisted/isWishlisted";

const SingleProductBasicDetails = ({ product }) => {
    const [wishlisted, setWishlisted] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);
    const dispatch = useDispatch();

    // localstorage
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    const cart = JSON.parse(localStorage.getItem("cart")) || [];

    const { name, price, discount, _id, rating } = product || {};

    const { priceCalculator, discountCalculator } = customFunction || {};

    // get total reviews info from api
    const { data: reviewsData, isLoading } = useGetAllReviewsByProductIDQuery({
        productID: _id,
    });

    const { total } = reviewsData || {};

    let totalReviews;
    if (isLoading) {
        totalReviews = <span>....</span>;
    } else if (!isLoading && total) {
        totalReviews = total;
    }

    console.log(wishlist);

    // function declaration
    const handleWishList = () => {
        const isWished = isWishlisted(product._id);
        if (!isWished) {
            dispatch(increaseWishlistCount());
            setWishlisted(true);
            wishlist.push(product);
            localStorage.setItem("wishlist", JSON.stringify(wishlist));
        } else {
            alert("Product is already added");
        }
    };

    const handleCart = () => {
        dispatch(increaseCartCount());
        setAddedToCart(true);
        cart.push(product);
        localStorage.setItem("cart", JSON.stringify(cart));
    };

    useEffect(() => {
        setWishlisted(isWishlisted(_id));
        setAddedToCart(isAlreadyProductinCart(_id));
    }, [_id]);

    return (
        <div>
            <h1 className="text-[#18181B] text-3xl leading-10 font-bold mb-4">{name}</h1>
            <div className="flex items-center gap-3 mb-8">
                <Rating name="productRating" value={rating} readOnly precision={0.5} />
                <p className="text-sm leading-5 font-medium text-[#52525B]">{totalReviews} Reviews</p>
            </div>
            <div className="flex items-center gap-3 mb-3">
                <div className="flex items-center text-[#18181B] text-3xl leading-10 font-bold">
                    <TbCurrencyTaka />
                    <p>{priceCalculator(price, discount || null)}</p>
                </div>
                {discount && (
                    <div className="flex items-center text-[#71717A] text-xl leading-8 font-bold line-through">
                        <TbCurrencyTaka />
                        <p>{discountCalculator(price, discount || null)}</p>
                    </div>
                )}
            </div>
            {discount && (
                <div className="flex items-center gap-1 text-[#71717A] text-sm leading-5 font-medium mb-8">
                    <HiTag />
                    <p>Save {discount}% right now</p>
                </div>
            )}
            {/* Submit btn */}
            <div className="flex items-center gap-3 mb-8">
                <button
                    className={`text-white w-[200px] h-[50px] rounded-md text-center font-bold ${
                        addedToCart ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    style={{
                        backgroundImage: "linear-gradient(90deg, #E22987 0%, #A82BC5 100%)",
                    }}
                    onClick={handleCart}
                    disabled={addedToCart}
                >
                    {addedToCart ? <p className="cursor-not-allowed">Already in Cart</p> : <p>Add To Cart</p>}
                </button>
                <button
                    className={`border border-[#D4D4D8] hover:border-[#b7b7bd] duration-300 ${
                        wishlisted ? "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]" : "bg-[#FAFAFB]"
                    }  rounded-md w-[50px] h-[50px] flex justify-center items-center`}
                    onClick={handleWishList}
                >
                    {wishlisted ? <AiFillHeart className="text-[26px] text-white" /> : <AiOutlineHeart className="text-[26px]" />}
                </button>
            </div>
            <div>
                <div className="flex items-center gap-2 text-[#52525B] mb-4">
                    <HiOutlineGlobe className="text-[16px]" />
                    <p className="text-sm font-medium">Free shipping worldwide</p>
                </div>
                <div className="flex items-center gap-2 text-[#52525B] mb-4">
                    <TbCreditCard className="text-[16px]" />
                    <p className="text-sm font-medium">100% Secured Payment</p>
                </div>
                <div className="flex items-center gap-2 text-[#52525B] mb-4">
                    <BiUser className="text-[16px]" />
                    <p className="text-sm font-medium">Made by the Professionals</p>
                </div>
            </div>
        </div>
    );
};

export default SingleProductBasicDetails;

import React from "react";

// components
import { TopMenu } from "../../../Wrapper/HomePage/mobileversion/MobileHome/TopMenu/TopMenu";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";

export const NavFooterLayout = ({ children }) => {
    return (
        <div>
            <div className="hidden lg:block">
                <NavBar />
            </div>
            <div className="lg:hidden">
                <TopMenu />
            </div>
            <section>{children}</section>
            <div className="hidden lg:block mt-[100px]">
                <Footer />
            </div>
        </div>
    );
};

import getCookie from "../../../Helper/cookies/getCookie";
import apiSlice from "../../api/apiSlice";

export const contactApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBundles: builder.query({
      query: () => ({
        url: `/bundle/shop/get-all`,
        method: "GET",
      }),
    }),
    getBundleById: builder.query({
      query: (id) => ({
        url: `/bundle/shop/get-bundle-id/${id}`,
        method: "GET",
      }),
    }),
    buyBundle: builder.mutation({
      query: ({ id, data }) => ({
        url: `/bundle/shop/buy-bundle/${id}`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
    buyBundleByPoint: builder.mutation({
      query: ({ id, data }) => ({
        url: `/bundle/shop/buy-bundle-by-point/${id}`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
    buyBundleByCash: builder.mutation({
      query: ({ id, data }) => ({
        url: `/bundle/shop/buy-bundle-by-cash/${id}`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${getCookie("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllBundlesQuery,
  useGetBundleByIdQuery,
  useBuyBundleMutation,
  useBuyBundleByPointMutation,
  useBuyBundleByCashMutation,
} = contactApi;

// Configuration
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import myAccountRouteRenderer from "./myAcount/myAccountRouteRenderer";
import shopRouteRenderer from "./shop/PrivateRoutes/shopRouteRenderer";
import shopPublicRouteRenderer from "./shop/PublicRoutes/shopPublicRouteRenderer";

// Components

const MasterRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* SHOP PRIVATE ROUTES */}
                {shopRouteRenderer()}
                {/* SHOP PUBLIC ROUTES */}
                {shopPublicRouteRenderer()}

                {/* Profiles */}
                {myAccountRouteRenderer()}
            </Routes>
        </BrowserRouter>
    );
};

export default MasterRoute;

import React from "react";
import { BiUser } from "react-icons/bi";
import { HiOutlineGlobe } from "react-icons/hi";
import { TbCreditCard, TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const BundleBasicDetails = ({ bundle, profile, setShowPopup }) => {
  const navigate = useNavigate();
  const { name, price, _id } = bundle || {};
  const canBuy = profile?.shopPackage?.package?.specialBundle;

  const handleBuyNow = () => {
    if (canBuy) {
      navigate(`/bundle-checkout/${_id}`);
    } else {
      setShowPopup(true);
    }
  };
  return (
    <div>
      <h1 className="text-[#18181B] text-3xl leading-10 font-bold mb-4">
        {name}
      </h1>
      <div className="flex items-center gap-3 mb-8">
        {/* <Rating
            name="productRating"
            value={rating}
            readOnly
            precision={0.5}
          /> */}
        {/* <p className="text-sm leading-5 font-medium text-[#52525B]">
            {totalReviews} Reviews
          </p> */}
      </div>
      <div className="flex items-center gap-3 mb-3">
        <div className="flex items-center text-[#18181B] text-3xl leading-10 font-bold">
          <TbCurrencyTaka />
          <p>{price}</p>
        </div>
        {/* <div className="flex items-center text-[#71717A] text-xl leading-8 font-bold line-through">
            <TbCurrencyTaka />
            <p>{oldPrice ? oldPrice : (Number(price) * discount) / 100}</p>
          </div> */}
      </div>
      {/* <div className="flex items-center gap-1 text-[#71717A] text-sm leading-5 font-medium mb-8">
          <HiTag />
          <p>Save {discount}% right now</p>
        </div> */}
      {/* Submit btn */}
      <div className="flex items-center gap-3 mb-8">
        <button
          className={`text-white w-[200px] h-[50px] rounded-md text-center font-bold cursor-pointer`}
          style={{
            backgroundImage: "linear-gradient(90deg, #E22987 0%, #A82BC5 100%)",
          }}
          onClick={handleBuyNow}
        >
          Buy Now
        </button>
        {/* <button
          className={`border border-[#D4D4D8] hover:border-[#b7b7bd] duration-300 ${
            wishlisted
              ? "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]"
              : "bg-[#FAFAFB]"
          }  rounded-md w-[50px] h-[50px] flex justify-center items-center`}
          onClick={handleWishList}
        >
          {wishlisted ? (
            <AiFillHeart className="text-[26px] text-white" />
          ) : (
            <AiOutlineHeart className="text-[26px]" />
          )}
        </button> */}
      </div>
      <div>
        <div className="flex items-center gap-2 text-[#52525B] mb-4">
          <HiOutlineGlobe className="text-[16px]" />
          <p className="text-sm font-medium">Free shipping worldwide</p>
        </div>
        <div className="flex items-center gap-2 text-[#52525B] mb-4">
          <TbCreditCard className="text-[16px]" />
          <p className="text-sm font-medium">100% Secured Payment</p>
        </div>
        <div className="flex items-center gap-2 text-[#52525B] mb-4">
          <BiUser className="text-[16px]" />
          <p className="text-sm font-medium">Made by the Professionals</p>
        </div>
      </div>
    </div>
  );
};

export default BundleBasicDetails;

// Configuration
import React, { useState } from "react";

// Third party packages
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SupportIcon from "@mui/icons-material/Support";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// components
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import { CustomHeader } from "../../shared/CustomHeader/CustomHeader";
import { BottomNav } from "../../../Wrapper/HomePage/mobileversion/BottomNav";

const theme = createTheme({
    // Define your theme properties here
    // For example, palette, typography, etc.
});

export const ShopProfileDashboard = () => {
    const [name, setName] = useState("Profile");
    const isMobile = useMediaQuery("(max-width:600px)");

    const nameHandler = data => {
        switch (data) {
            case "My Account":
                // code block
                setName("Account");
                break;
            case "My Orders":
                setName("Orders");
                // code block
                break;
            case "My Wallet":
                setName("Wallet");
                // code block
                break;
            case "Support":
                setName("Support");
                // code block
                break;
            default:
            // code block
        }
    };

    const endpointGenerator = text => {
        switch (text) {
            case "My Account":
                return "account";

            case "My Orders":
                return "my-orders";

            case "My Wallet":
                return "my-wallet";
            case "Support":
                return "support";
            default:
                return "userProfile";
        }
    };

    const iconGenerator = text => {
        switch (text) {
            case "My Account":
                return <AccountBoxIcon />;

            case "My Orders":
                return <LocalShippingIcon />;
            case "My Wallet":
                return <WalletOutlinedIcon />;
            case "Support":
                return <SupportIcon />;

            default:
                break;
        }
    };
    return (
      <MuiThemeProvider theme={theme}>
        <section>
          <div className="hidden lg:block">
            <CustomHeader title={name} />
          </div>
          <div className="lg:hidden">
            <MobileBackButton name="My Account" />
          </div>
          <div
            className="lg:w-[1200px] flex gap-x-[10px] lg:gap-x-[20px] mx-auto px-[6px] lg:px-[20px]"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="w-[45px] lg:w-[204px] h-[100vh-30vh] py-[20px] relative">
              <div className="sticky top-0">
                <List>
                  {["My Account", "My Orders", "My Wallet", "Support"].map(
                    (text, index) => (
                      <ListItem key={text} disablePadding>
                        <NavLink
                          to={endpointGenerator(text)}
                          className={({ isActive }) =>
                            isActive
                              ? "bg-[#F1F5F9] w-[55px] lg:w-[200px]"
                              : "bg-white w-[55px] lg:w-[200px]"
                          }
                          onClick={() => nameHandler(text)}
                        >
                          <ListItemButton>
                            <ListItemIcon>{iconGenerator(text)}</ListItemIcon>
                            <ListItemText
                              primary={text}
                              style={{ display: isMobile ? "none" : "flex" }}
                            />
                          </ListItemButton>
                        </NavLink>
                      </ListItem>
                    )
                  )}
                </List>
              </div>
            </div>
            <div className="flex-1 border-l-[1px] border-[#eeeeee] p-[10px] lg:p-[20px] h-auto relative w-[calc(100%-70px)]">
              <Outlet />
            </div>
            <div className="lg:hidden">
              <BottomNav />
            </div>
          </div>
        </section>
      </MuiThemeProvider>
    );
};

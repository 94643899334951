import React from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";
import bundle from "./../../../../assets/images/bundle.png";

const ShopBundleCard = ({ item, setShowPopup, profile }) => {
    const { price, image, _id, name } = item || {};
    const navigate = useNavigate();
    const canBuy = profile?.shopPackage?.package?.specialBundle;
    const handleBuyNow = () => {
        if (canBuy) {
            navigate(`/bundle-checkout/${_id}`);
        } else {
            setShowPopup(true);
        }
    };
    return (
        <VarientAnimation direction="up" delay={0.3}>
            <div className="w-full h-[350px] relative shadow hover:shadow-xl md:w-[220px] mx-auto cursor-pointer overflow-hidden bg-gray-100 rounded">
                <div
                    className="bg-slate-100 w-full overflow-hidden  h-[220px] flex justify-center items-center"
                    onClick={() => navigate(`/bundle/${_id}`)}
                >
                    <img src={image} className="h-full w-full" alt="productImage" />
                </div>
                <span className="absolute -top-3 -right-8 w-[150px] rotate-45">
                    <img className="w-full" src={bundle} alt="" />
                </span>

                <section className="flex flex-col items-start gap-y-[5px]">
                    <div className="flex justify-between items-start p-[10px] font-outfit w-full" onClick={() => navigate(`/bundle/${_id}`)}>
                        <h3 className="font-medium text-[14px]">{name}</h3>
                        <p className="font-medium flex items-center">
                            <TbCurrencyTaka />
                            {price}
                        </p>
                    </div>
                    {/* <Rating value={rating || 0} readOnly size="small" /> */}
                    <div className="flex items-center justify-center absolute bottom-2 w-full">
                        <button onClick={handleBuyNow} className={`px-4 w-[90%] text-[14px] py-2  duration-300 font-bold special_profile_button`}>
                            Buy Now
                        </button>
                    </div>
                </section>
            </div>
        </VarientAnimation>
    );
};

export default ShopBundleCard;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
    category: "All",
    minPriceRange: 200,
    maxPriceRange: 5000,
};

const shopFilterSliceV3 = createSlice({
    name: "shopFilterV3",
    initialState,
    reducers: {
        setCategory: (state = initialState, action) => {
            state.category = action.payload;
        },
        setSearchTerm: (state = initialState, action) => {
            state.search = action.payload;
        },
        setStatePriceRange: (state = initialState, action) => {
            const { min, max } = action.payload;
            state.minPriceRange = min;
            state.maxPriceRange = max;
        },
    },
});

export default shopFilterSliceV3.reducer;
export const { setCategory, setSearchTerm, setStatePriceRange } = shopFilterSliceV3.actions;

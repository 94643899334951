import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllCategoryQuery } from "../../../../Redux/features/Shop/shopApi";
import { setCategory } from "../../../../Redux/features/Shop/shopFilterSliceV3";

export const CategorySelector = () => {
    const currentCategory = useSelector(state => state.persistedReducer.shopFilter.category);
    const dispatch = useDispatch();
    const { isLoading, data, error } = useGetAllCategoryQuery();
    const { category } = data || {};
    console.log(category)

    let categories;

    if (isLoading) {
        return;
    } else if (!isLoading && category?.length > 0) {
        categories = category?.map(item => {
            return {
                _id: item?._id,
                name: item?.name,
            };
        });
        categories = [{
            _id: String(Math.floor(Math.random() * 10000)),
            name: "All",
        },...categories]

        // categories[0] = {
        //     _id: String(Math.floor(Math.random() * 10000)),
        //     name: "All",
        // };
    }

    //  function declare
    const handleCategory = item => {
        dispatch(setCategory(item));
    };

    let content;
    if (isLoading) {
        return;
    } else if (!isLoading && error) {
        content = "something went wrong";
    } else if (!isLoading && data) {
        content = (
            <div className="flex flex-col items-start w-full">
                <h3 className="font-medium font-Inter text-left lg:mt-[30px]">Category</h3>
                <div>
                    {categories?.map(item => {
                        return (
                            <p
                                key={item._id}
                                className={`text-[14px] text-gray-500 ${
                                    currentCategory === item.name && "font-semibold underline text-gray-600"
                                } hover:underline hover:text-gray-600 cursor-pointer`}
                                onClick={() => handleCategory(item?.name)}
                            >
                                {item.name}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }
    return content;
};

import React from "react";
import { useGetAllCategoryQuery } from "../../../../Redux/features/Shop/shopApi";

const MobileCategorySelector = ({ currentCategory, setCurrentCategory }) => {
    const { isLoading, data, error } = useGetAllCategoryQuery();
    const { category } = data || {};

    let categories;

    if (isLoading) {
        return;
    } else if (!isLoading && category?.length > 0) {
        categories = category?.map(item => {
            return {
                _id: item?._id,
                name: item?.name,
            };
        });

        categories[0] = {
            _id: String(Math.floor(Math.random() * 10000)),
            name: "All",
        };
    }

    // function declaration
    const handleCategory = item => {
        setCurrentCategory(item);
    };

    let content;
    if (isLoading) {
        return;
    } else if (!isLoading && error) {
        content = "something went wrong";
    } else if (!isLoading && data) {
        content = (
            <div className="flex flex-col items-start w-full">
                <h3 className="font-medium font-Inter text-left lg:mt-[30px]">Category</h3>
                <div>
                    {categories?.map(item => {
                        return (
                            <p
                                key={item._id}
                                className={`text-[14px] text-gray-500 ${
                                    currentCategory === item.name && "font-semibold underline text-gray-600"
                                } hover:underline hover:text-gray-600 cursor-pointer`}
                                onClick={() => handleCategory(item?.name)}
                            >
                                {item.name}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }
    return content;
};

export default MobileCategorySelector;

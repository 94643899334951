/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party packages
import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";

//components
import { usePlaceOrderByPointMutation, usePlaceOrderMutation } from "../../../../../Redux/features/Shop/shopApi";
import { clearCartCount, clearShopSlice } from "../../../../../Redux/features/Shop/shopSlice";
import { resetBillingSummaryState } from "../../../../../Redux/features/checkout/billingSummarySlice";
import { useAmrPayCheckoutOrderMutation } from "../../../../../Redux/features/checkout/checkoutApi";
import { getTitleDecision } from "../../../../../assets/utilities/CheckoutHelperCalculation/checkoutHelperCalculation";
import { checkError, checkObject, handleCustomMessage, removeDuplicates } from "../../../../../assets/utilities/orderPlacement/orderPlacement";
import { OvalLoader } from "../../../../shared/Cards/Loader/OvalLoader/OvalLoader";
import Error from "../../../../ui/error/Error";
import { SuccessSnackBar } from "../../../../ui/error/snackBar/SuccessSnackBar";

export const OrderPlacement = ({ grandTotal, discount }) => {
    const [comment, setComment] = useState();
    const [displayErrors, setDisplayErrors] = useState(false);
    const [customErrorMessage, setCustomErrorMessage] = useState([]);
    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    const [privacyChecked, setPrivacyCheked] = useState(false);

    //point detailes
    const pointDetailes = useSelector(state => state.persistedReducer.billingSummaryPoint.billingSummaryWithPoint);
    const { subTotal: pointSubTotal, shipping: pointShipping, tax: pointTax } = pointDetailes || {};

    //price detailes
    const { checkoutDetailes, billingSummary } = useSelector(state => state.persistedReducer) || {};
    const { subTotal, tax, shipping: priceForShipping } = billingSummary?.billingSummary || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Redux hook
    const [amrPayCheckout, { data, isLoading: amrPayLoading }] = useAmrPayCheckoutOrderMutation();
    const [placeOrder, { isLoading, isSuccess }] = usePlaceOrderMutation();
    const [placeOrderByPoint, { isSuccess: pointSuccess, isLoading: pointOrderLoading, error: pointOrderError }] = usePlaceOrderByPointMutation();

    const orderItems = billingSummary?.orderItems;

    const { shippingDetailes, billingDetailes, paymentMethod } = checkoutDetailes || {};

    // function declaration
    // order handler
    const handleOrderPlace = () => {        
        let data;
        if (paymentMethod === "amarPay" || paymentMethod === "cash") {
            data = {
                shippingInfo: shippingDetailes,
                billingInfo: billingDetailes,
                totalPrice: Number(subTotal + 100 + tax),
                orderItems: orderItems,
                deliveryCharge: priceForShipping,
                tax,
                comment,
                discount,
            };
        } else if (paymentMethod === "point") {
            data = {
                shippingInfo: shippingDetailes,
                billingInfo: billingDetailes,
                totalPrice: Number(pointSubTotal + pointShipping + pointTax),
                orderItems: orderItems,
                deliveryCharge: pointShipping,
                currency: "point",
                tax: pointTax,
                comment,
                discount,
            };
        }
        // Error handle End
        if (customErrorMessage.length === 0) {
            if (paymentMethod === "amarPay") amrPayCheckout(data);
            if (paymentMethod === "cash") placeOrder(data);
            if (paymentMethod === "point") placeOrderByPoint(data);
        } else {
            setDisplayErrors(true);
        }
    };
    useEffect(()=>{

    })
    useEffect(() => {
        if (data?.data) {
            window.location.replace(data?.data);
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess) {
            localStorage.removeItem("cart");
            dispatch(resetBillingSummaryState());
            dispatch(clearCartCount());
            dispatch(clearShopSlice());

            setTimeout(() => {
                if (paymentMethod === "cash") {
                    navigate("/dashboard/my-orders");
                } else {
                    <Navigate to="payment_Success" state={{ from: location }} />;
                }
            }, 2000);
        }
    }, [isSuccess, navigate, dispatch, paymentMethod, location]);

    useEffect(() => {
        checkError(privacyChecked, shippingDetailes, billingDetailes);
    }, [privacyChecked, shippingDetailes, billingDetailes]);

    // point order success
    useEffect(() => {
        if (pointSuccess) {
            setSuccessSnackBarOpen(true);

            setTimeout(() => {
                localStorage.removeItem("cart");
                dispatch(resetBillingSummaryState());
                dispatch(clearCartCount());
                navigate("/payment_Success");
            }, 2000);
        }
    }, [pointSuccess, navigate, dispatch]);

    //point order error
    useEffect(() => {
        if (pointOrderError) {
            setCustomErrorMessage(["You do not have enough point."]);
            setDisplayErrors(true);
        }
    }, [pointOrderError]);

    useEffect(() => {
        // Error handle start
        setCustomErrorMessage(customErrorMessage?.filter(item=>item!=="You do not have enough point."))
        if (!privacyChecked) {
            setCustomErrorMessage(["Please checked our terms and condition"]);
            return;
        }        

        let shippingInfoProperties = checkObject(shippingDetailes);
        if (shippingInfoProperties.includes("address2")) {
            shippingInfoProperties = shippingInfoProperties.filter(item => item !== "address2");
        }

        let billingInfoProperties = checkObject(billingDetailes);
        if (billingInfoProperties.includes("address2")) {
            billingInfoProperties = billingInfoProperties.filter(item => item !== "address2");
        }

        const errorsArray = [...shippingInfoProperties, ...billingInfoProperties];
        const updatedErrorsArray = removeDuplicates(errorsArray);

        handleCustomMessage(updatedErrorsArray, setCustomErrorMessage);
    }, [billingDetailes, privacyChecked, shippingDetailes,useSelector(state => state.persistedReducer)]);

    // checkbox handler
    const handleCheckBox = e => {
        setPrivacyCheked(e.target.checked);
        setCustomErrorMessage(customErrorMessage.filter(item => item !== "Please checked our terms and condition"));
    };
    return (
        <div>
            <div className="">
                <TextField
                    id="outlined-multiline-flexible"
                    label="Order Comment"
                    placeholder="Type here..."
                    multiline
                    rows={4}
                    className="w-full"
                    onChange={e => setComment(e.target.value)}
                />
            </div>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={privacyChecked} name="gilad" onChange={handleCheckBox} />}
                        label={
                            <p className="text-[14px]">
                                I agree with the <span className="text-blue-500">Terms</span> and
                                <span className="text-blue-500"> Condition</span>
                            </p>
                        }
                        sx={{
                            paddingY: "5px",
                            paddingLeft: "10px",
                        }}
                    />
                </FormGroup>
            </FormControl>
            {customErrorMessage?.length !== 0 && displayErrors && (
                <div className="w-full ">
                    {customErrorMessage?.map((item, index) => {
                        return <Error key={item} message={item} />;
                    })}
                </div>
            )}
            <button
                onClick={handleOrderPlace}
                className="w-full font-bold text-[#F6F6F6] py-[10px] rounded-md my-[10px]"
                style={{
                    backgroundImage: "linear-gradient(137.27deg, #EE2FFF 19.41%, #CD1D5C 65.49%)",
                    boxShadow: "0px 4px 4px rgba(14, 53, 191, 0.25)",
                }}
            >
                {isLoading || amrPayLoading || pointOrderLoading ? (
                    <OvalLoader color="#FFFFFF" height={25} />
                ) : (
                    <p>{getTitleDecision(paymentMethod, grandTotal)}</p>
                )}
            </button>

            {/* snackbar */}
            {successSnackBarOpen && (
                <div>
                    <SuccessSnackBar
                        successSnackBarOpen={successSnackBarOpen}
                        setSuccessSnackBarOpen={setSuccessSnackBarOpen}
                        message="Order Placed"
                    />
                </div>
            )}
        </div>
    );
};

import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useGetAllBundlesQuery } from "../../../Redux/features/bundle/bundleApi";
import { useGetProfileDetailsWIthAuthQuery } from "../../../Redux/features/userInfo/userApi";
import GoPremium from "../../shared/Packages/GoPremium";
import ShopBundleCard from "../Shop/bundle/ShopBundleCard";
import { LineWaveLoader } from "../../shared/Cards/Loader/lineWaveLoader/LineWaveLoader";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";
const message =
  'You need to Update you package to buy a bundle. Please buy "Bundle" package to buy a bundle';
export const ShopBundle = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  //API
  const { data: profile, isLoading: profLoading } =
    useGetProfileDetailsWIthAuthQuery();
  const { data, isLoading, isError } = useGetAllBundlesQuery();
  const { data: courseBundle } = data || {};

  let content;
  if (isLoading || profLoading) {
    content = <LineWaveLoader />;
  } else if (!isLoading && isError) {
    content = <ServerErrorMessage />;
  } else if (!isLoading && courseBundle?.length === 0) {
    content = (
      <div className="w-full h-[350px] flex justify-center items-center font-Inter text-gray-500 text-[20px]">
        No Course Found!
      </div>
    );
  } else if (!isLoading && profile && courseBundle?.length > 0) {
    content = (
      <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-[50px] px-[16px] gap-[20px]">
        {courseBundle?.slice(0, 4).map((item) => {
          return (
            <ShopBundleCard
              {...{ item, profile, setShowPopup }}
              key={item._id}
            />
          );
        })}
      </div>
    );
  }
  return (
    <>
      <section className="max-w-[1200px] mx-auto mt-[100px]">
        <div className="flex justify-between items-center px-[16px]">
          <h1 className="text-[16px] lg:text-[24px] font-Poppins font-semibold">
            Special Bundles for You
          </h1>
          {courseBundle?.length > 4 && (
            <button
              className="flex items-center group"
              onClick={() => navigate("/bundle")}
            >
              See all{" "}
              <span>
                <BsArrowRight className="text-[14px] ml-[4px] group-hover:ml-[12px] duration-300" />
              </span>
            </button>
          )}
        </div>
        <div>{content}</div>
      </section>
      {showPopup && <GoPremium {...{ message, setShowPopup }} />}
    </>
  );
};

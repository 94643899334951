// This function take two arg SubToal and parcentage and return a calculated parcentage
export const calculatePercentage = (number, percentage) => {
    return number * (percentage / 100);
};

//This function take an array of products and return total prices of all products
export const sumPrices = products => {
    let sum = 0;
    for (let i = 0; i < products.length; i++) {
        sum += products[i].price;
    }
    return sum;
};

export const subPricesWithPoint = products => {
    let sum = 0;
    for (let i = 0; i < products.length; i++) {
        sum += products[i].priceInPoint;
    }

    return sum;
};

export const getBillingInfoRenderingDecision = (paymentMethod, bdtInfo, pointInfo) => {
    if (paymentMethod === "point") {
        return pointInfo;
    } else if (paymentMethod === "cash" || paymentMethod === "amarPay") {
        return bdtInfo;
    }
};

export const getTitleDecision = (paymentMethod, taka) => {
    if (paymentMethod === "cash" || paymentMethod === "amarPay") {
        return <span>{`Pay BDT ${taka}`}</span>;
    } else if (paymentMethod === "point") {
        return <span>{`Pay Point ${taka}`}</span>;
    }
};

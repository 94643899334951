import React from "react";
import { BottomNav } from "../../../Wrapper/HomePage/mobileversion/BottomNav";
import { TopMenu } from "../../../Wrapper/HomePage/mobileversion/MobileHome/TopMenu/TopMenu";
import CustomHeader from "../CustomHeader/CustomHeader";
import Footer from "../Footer/Footer";

export const MainLayout = ({ children, title }) => {
    return (
        <div>
            <div className="hidden lg:block">
                <CustomHeader title={title} />
            </div>
            <div className="block lg:hidden">
                <TopMenu />
            </div>
            <section>{children}</section>
            <div className="hidden lg:block mt-[100px]">
                <Footer />
            </div>
            <div className="lg:hidden">
                <BottomNav />
            </div>
        </div>
    );
};

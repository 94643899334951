//Configuration
import React, { useEffect, useState } from "react";

//Third partu package
import { Rating } from "@mui/material";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { increaseCartCount } from "../../../../Redux/features/Shop/shopSlice";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";
import customFunction from "../../../../assets/utilities/CustomFunction/customFunction";
import isAlreadyProductinCart from "../../../../assets/utilities/isAlreadyProductinCart/isAlreadyProductInCart";
import Image from "../../Web_Components/Image";

export const ProductCard = ({ item }) => {
    const [addedToCart, setAddedToCart] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { name, photos, price, rating, _id: productID } = item || {};

    const photoUrl = photos[0]?.url || "";

    // function for truncate string
    const { truncateString } = customFunction || {};

    name = truncateString(name);

    //LocalStorage
    const setItemInLocalStorage = () => {
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
        localStorage.setItem("cart", JSON.stringify([...cart, item]));
    };

    const handleCart = () => {
        dispatch(increaseCartCount());
        setAddedToCart(true);
        setItemInLocalStorage();
    };

    // check is this item is already in cart or not
    useEffect(() => {
        setAddedToCart(isAlreadyProductinCart(productID));
    }, [productID]);
    return (
        <VarientAnimation direction="up" delay={0.3}>
            <div className="w-full md:w-[220px] mx-auto cursor-pointer">
                <div
                    className="bg-slate-100 w-full  h-[220px] flex justify-center items-center"
                    onClick={() => navigate(`/shop/singleProduct/${productID}`)}
                >
                    <Image photo={photoUrl} height="h-[220px]" width="w-full" />
                </div>
                <section className="flex flex-col items-start gap-y-[5px]">
                    <div className="flex justify-between p-[5px] font-outfit w-full" onClick={() => navigate(`/shop/singleProduct/${productID}`)}>
                        <h3 className="font-medium">{name}</h3>
                        <p className="font-medium flex items-center">
                            <TbCurrencyTaka />
                            {price}
                        </p>
                    </div>
                    <Rating value={rating || 0} readOnly size="small" />
                    <button
                        className={`px-4 hover:px-4 text-[14px] hover:text-[14px] hover:py-1 py-1 duration-300 ${
                            addedToCart ? "special_profile_button" : "special_profile_button_hover"
                        } ring-primary rounded-[50px]`}
                        onClick={handleCart}
                        disabled={addedToCart}
                    >
                        {addedToCart ? "Already in cart" : "Add to cart"}
                    </button>
                </section>
            </div>
        </VarientAnimation>
    );
};

import React, {useState} from "react";
import { useParams } from "react-router-dom";
import { useGetBundleByIdQuery } from "../../../Redux/features/bundle/bundleApi";
import BundleBasicDetails from "./BundleBasicDetails";
import SingleBundleOthersDetail from "./SingleBundleOthersDetail";
import SingleProductCard from "./SingleProductCard";
import { useGetProfileDetailsWIthAuthQuery } from "../../../Redux/features/userInfo/userApi";
import GoPremium from "../../shared/Packages/GoPremium";

const message =
  "You need to Update you package to buy a bundle. Please go and read our offers in packages";
const SingleBundleDetail = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const { data } = useGetBundleByIdQuery(id);
  const { data: profile } =
    useGetProfileDetailsWIthAuthQuery();
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between items-start mb-20 px-[16px]">
        <div className="w-full lg:max-w-[700px]">
          <div className="mb-[16px] lg:mb-20">
            <img className="w-full" src={data?.data?.image} alt="" />
          </div>
          <div className="hidden lg:block">
            <SingleBundleOthersDetail bundle={data?.data} />
            <h1 className="text-[20px] lg:text-[30px] font-bold font-outfit">
              This Bundle Include the following products
            </h1>
            {data?.data?.products?.map((item) => (
              <SingleProductCard product={item} />
            ))}
          </div>
        </div>
        <div className="w-full lg:max-w-[432px]">
          <BundleBasicDetails
            bundle={data?.data}
            profile={profile}
            setShowPopup={setShowPopup}
          />
          {/* <SingleProductBasicDetails {...{ product }} /> */}
          <div className=" lg:hidden mt-[12px]">
            {/* <SingleProductOtherDetails {...{ data, description }} /> */}
            <SingleBundleOthersDetail bundle={data?.data} />
            <h1 className="text-[24px] mt-6 lg:text-[34px] font-bold font-outfit">
              This Bundle Include the following products
            </h1>
            {data?.data?.products?.map((item) => (
              <SingleProductCard product={item} />
            ))}
          </div>
        </div>
      </div>
      {showPopup && <GoPremium {...{message, setShowPopup}} />}
    </>
  );
};

export default SingleBundleDetail;

import React from "react";

const ProductImagesCard = ({ setSelectedImage, selectedImage, photos }) => {
    return (
        <div>
            {photos.map((img, index) => (
                <img
                    className={`${
                        selectedImage === img?.url && "border-2 border-[#18181B]"
                    } w-[57px] lg:w-[97px] h-[54px] lg:h-[84px] rounded-md mb-6`}
                    key={index}
                    src={img?.url}
                    alt="productImage"
                    onClick={() => setSelectedImage(img?.url)}
                />
            ))}
        </div>
    );
};

export default ProductImagesCard;

import React from "react";
import { NavFooterLayout } from "../../../../components/shared/Lawout/NavFooterLayout";
import SingleProductDetails from "./SingleProductDetails";

export const SingleProductDeatilesContainer = () => {
    return (
        <NavFooterLayout>
            <section className="max-w-[1400px] mx-auto px-[16px] lg:mt-[120px]">
                <SingleProductDetails />
            </section>
        </NavFooterLayout>
    );
};

import { makeStyles } from "@material-ui/styles";
import { Slider } from "@mui/material";
import React from "react";
import { TbCurrencyTaka } from "react-icons/tb";

const useStyles = makeStyles({
    root: {
        color: "#ff317b",
    },
    thumb: {
        backgroundColor: "#ff317b",
    },
    track: {
        backgroundColor: "#ff317b",
    },
    rail: {
        backgroundColor: "#ff317b",
    },
});

const MobilePriceRangeSelector = ({ priceRange, setPriceRange }) => {
    const classes = useStyles();

    const handlePriceRange = (event, newValue) => {
        setPriceRange(newValue);
    };

    return (
        <div className="w-[180px] mt-[30px]">
            <label htmlFor="price" className="font-medium">
                Price
            </label>
            <p className="flex items-center gap-x-[3px] text-[12px]">
                <TbCurrencyTaka />
                {`${priceRange[0]} - ${priceRange[1]}`}
            </p>
            <Slider
                value={priceRange}
                onChange={handlePriceRange}
                valueLabelDisplay="auto"
                min={200}
                max={20000}
                classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    track: classes.track,
                    rail: classes.rail,
                }}
            />
        </div>
    );
};

export default MobilePriceRangeSelector;

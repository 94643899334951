import React from "react";
import Faq from "./Faq";

export const FaqContainer = () => {
    return (
        <div>
            <div className="flex flex-col items-center mt-[100px]">
                <div className="text-[32px] font-semibold font-outfit">FAQ's</div>
                <h3 className="text-[22px] font-medium font-Inter text-center">
                    You have question, we have <br /> the answer
                </h3>
            </div>
            <Faq />
        </div>
    );
};

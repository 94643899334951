import { makeStyles } from "@material-ui/styles";
import { Slider } from "@mui/material";
import React from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setStatePriceRange } from "../../../../Redux/features/Shop/shopFilterSliceV3";

const useStyles = makeStyles({
    root: {
        color: "#ff317b",
    },
    thumb: {
        backgroundColor: "#ff317b",
    },
    track: {
        backgroundColor: "#ff317b",
    },
    rail: {
        backgroundColor: "#ff317b",
    },
});

export const PriceRangeSelector = () => {
    const { minPriceRange, maxPriceRange } = useSelector(state => state.persistedReducer.shopFilter);
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        dispatch(
            setStatePriceRange({
                min: newValue[0],
                max: newValue[1],
            })
        );
    };

    return (
        <div className="w-[180px] mt-[30px]">
            <label htmlFor="price" className="font-medium">
                Price
            </label>
            <p className="flex items-center gap-x-[3px] text-[12px]">
                <TbCurrencyTaka />
                {`${minPriceRange} - ${maxPriceRange}`}
            </p>
            <Slider
                value={[minPriceRange, maxPriceRange]}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={200}
                max={20000}
                classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    track: classes.track,
                    rail: classes.rail,
                }}
            />
        </div>
    );
};

import { DesktopWishlistContainer } from "../../../Wrapper/Shop/wishList/DesktopWishlistContainer";
import { GoogleSignUpInfo } from "../../../components/pages/Authentications/GoogleLogin/GoogleSignUpInfo";
import { OTP } from "../../../components/pages/Authentications/OTP/OTP";
import Bundle from "../../../components/pages/Bundle/Bundle";
import BundleCheckout from "../../../components/pages/BundleCheckout/BundleCheckout";
import { CheckoutPage } from "../../../components/pages/Checkout/CheckoutPage";
import { NotificationContainer } from "../../../components/pages/Notification/NotificationContainer/NotificationContainer";
import { ShopSendReview } from "../../../components/pages/ShopReview/ShopSendReview/ShopSendReview";
import { BundleCart } from "../../../components/pages/bundleCart/BundleCart";
import { Cart } from "../../../components/pages/cart/Cart";
import Packages from "../../../components/pages/packages/Packages";
import { PaymentSuccess } from "../../../components/shared/PaymentSuccess/PaymentSuccess";

const shopPrivateRoutes = [
    {
        id: 2,
        path: "/cart",
        element: <Cart />,
    },
    {
        id: 3,
        path: "/checkout/:id",
        element: <CheckoutPage />,
    },
    {
        id: 4,
        path: "/wishlist",
        element: <DesktopWishlistContainer />,
    },
    {
        id: 11,
        path: "/packages",
        element: <Packages />,
    },
    {
        id: 12,
        path: "/bundle",
        element: <Bundle />,
    },

    {
        id: 13,
        path: "/bundle-checkout/:id",
        element: <BundleCheckout />,
    },
    {
        id: 13,
        path: "/bundle/:id",
        element: <BundleCart />,
    },
    {
        id: 14,
        path: "/payment_Success",
        element: <PaymentSuccess />,
    },
    {
        id: 15,
        path: "/otp",
        element: <OTP />,
    },
    {
        id: 16,
        path: "/registration-info",
        element: <GoogleSignUpInfo />,
    },
    {
        id: 17,
        path: "/notifications",
        element: <NotificationContainer />,
    },
    {
        id: 18,
        path: "/sendReview/:id",
        element: <ShopSendReview />,
    },
];

export default shopPrivateRoutes;

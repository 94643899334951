import React, { useEffect } from "react";
// import PackageContainer from "./PackageContainer";
import { useLocation } from "react-router-dom";
import { MainLayout } from "../../shared/Lawout/MainLayout";
import PackageContainer from "./packageContainer";

const Packages = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top of the page whenever the location changes
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <MainLayout title="Package">
                <PackageContainer />
            </MainLayout>
        </>
    );
};

export default Packages;

import React,{useEffect} from "react";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import BundleContainer from "./BundleContainer";
import { TopMenu } from "../../../Wrapper/HomePage/mobileversion/MobileHome/TopMenu/TopMenu";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import { useLocation } from "react-router-dom";

const Bundle = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <div className="hidden lg:block">
        <CustomHeader title="Bundle" />
      </div>
      <div className="block lg:hidden">
        <TopMenu />
        <MobileBackButton />
      </div>
      <BundleContainer />
      {/* <div className="block lg:hidden">
          <TopMenu />
        </div>
        <BundleContainer />
        <FilterContainer /> */}
    </div>
  );
};

export default Bundle;

import React, { useState } from "react";

//Components
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetAllProductsQuery } from "../../../../Redux/features/Shop/shopApi";
import notifyImage from "../../../../assets/images/icons/notify.svg";
import { LineWaveLoader } from "../../../shared/Cards/Loader/lineWaveLoader/LineWaveLoader";
import { ProductCard } from "../../../shared/Cards/Product/ProductCard";
import { ServerErrorMessage } from "../../../ui/error/ServerErrorMessage";

export const AllProducts = ({limit}) => {    
    const [page, setPage] = useState(1);
    const { search, category, minPriceRange, maxPriceRange } = useSelector(state => state.persistedReducer.shopFilter);
    const { data, isLoading, isError, isFetching } = useGetAllProductsQuery({
        page: page,
        limit: limit || 12,
        category: categoryDecisionMaker(category),
        minPrice: minPriceRange,
        maxPrice: maxPriceRange,
        searchTerm: search || "",
    });

    const { products, total } = data || {};
    const totalPage = Math.ceil(total / (limit || 12));

    //  function declaration
    function categoryDecisionMaker(category) {
        if (category.includes("All")) {
            return "";
        } else {
            return category;
        }
    }

    let content;
    if (isLoading || isFetching) {
        content = (
            <div>
                <LineWaveLoader title="Product is Loading..." />
            </div>
        );
    } else if (!isLoading && isError) {
        content = (
            <div>
                <ServerErrorMessage />
            </div>
        );
    } else if (!isLoading && products?.length === 0) {
        content = (
            <div className="w-full flex flex-col justify-center items-center h-full pb-[100px]">
                <img className="w-[200px] h-[200px]" src={notifyImage} alt="notify" />
                <p className="text-[16px] text-gray-500">No Product found!</p>
            </div>
        );
    } else if (!isLoading && products?.length > 0) {
        content = (
            <section className="mb-[100px]">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-[25px]">
                    {products?.map(item => {
                        return <ProductCard item={item} key={item._id} />;
                    })}
                </div>
                {totalPage > 1 && (
                    <div className="mt-[60px] flex justify-center items-center">
                        <Pagination count={totalPage} size="small" variant="outlined" color="secondary" onChange={(event, value) => setPage(value)} />
                    </div>
                )}
            </section>
        );
    }
    return content;
};

// configuration
import React from "react";

// components
import { MobileBackButton } from "../../shared/Components/MobileBackButton";

import NavBar from "../../shared/NavBar/NavBar";
import { TopMenu } from "../../../Wrapper/HomePage/mobileversion/MobileHome/TopMenu/TopMenu";
import SingleBundleDetail from "./SingleBundleDetail";

export const BundleCart = () => {
   
  return (
    <div className="pb-[250px]">
      <div className="hidden lg:block">
        <NavBar />
      </div>
      <div className="lg:hidden">
        <TopMenu />
        <MobileBackButton />
      </div>
      <section className="max-w-[1400px] mx-auto px-[16px] lg:mt-[120px]">
        {/* <SingleProductDetails /> */}
        <SingleBundleDetail />
      </section>
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { TbCurrencyTaka } from "react-icons/tb";

const SingleProductCard = ({ product }) => {
  const navigate = useNavigate();
  const { name, photos, _id, price } = product?.product || {};
  return (
    <div
      onClick={() => navigate(`/shop/singleProduct/${_id}`)}
      className="bg-gray-50 my-4 flex hover:shadow-lg"
    >
      <div className="max-w-[120px]">
        <img src={photos && photos[0]?.url} alt="Product_image" />
      </div>
      <div className="p-2">
        <h1 className="text-[#354895] text-[20px] lg:text-[30px] font-bold font-outfit">
          {name}
        </h1>
        <p className="text-[14px] lg:text-[20px]">{product?.quantity} piece</p>
        <p className="flex items-center">
          <TbCurrencyTaka className="text-[18px] lg:text-[24px]" /> {price}
        </p>
      </div>
    </div>
  );
};

export default SingleProductCard;

import React, { Suspense } from "react";

// Components
import { Banner } from "../../components/pages/Home/Banner/Banner";
// import { BestElectronics } from "../../components/pages/Home/BestElectronics/BestElectronics";
import { DiscoverNewItems } from "../../components/pages/Home/DiscoverNewItems/DiscoverNewItems";
import { FaqContainer } from "../../components/pages/Home/Faq/FaqContainer";
import { ServiceHighlight } from "../../components/pages/Home/ServiceHighlight/ServiceHighlight";
import Footer from "../../components/shared/Footer/Footer";
import NavBar from "../../components/shared/NavBar/NavBar";
import { TopMenu } from "./mobileversion/MobileHome/TopMenu/TopMenu";
import { BottomNav } from "./mobileversion/BottomNav";
import { BrandLoader } from "../../components/shared/Cards/Loader/BrandLoader/BrandLoader";

//css
import "../../components/shared/NavBar/TopMenu.css";
import PackagePromo from "../../components/pages/PackagePromo/PackagePromo";
import { ShopBundle } from "../../components/pages/Home/ShopBundle";
import { FilterContainer } from "../../components/pages/Shop/FilterContainer";

const HomePage = () => {
  return (
    <div>
      <Suspense fallback={<BrandLoader />}>
        <div className="hidden lg:block">
          <NavBar />
        </div>
        <div className="lg:hidden mov-res">
          <TopMenu />
        </div>
        <Banner />
        <FilterContainer {...{limit:4}} />
        {/* <BestElectronics /> */}
        <DiscoverNewItems />
        <ShopBundle />
        <ServiceHighlight />
        <PackagePromo />
        <FaqContainer />
        <div className="hidden lg:block">
          <Footer />
        </div>

        <div className="lg:hidden">
          <div className="h-24"></div>
          <BottomNav />
        </div>
      </Suspense>
    </div>
  );
};

export default HomePage;

import React from 'react';

const SingleBundleOthersDetail = ({ bundle }) => {
    const {description} = bundle || {}
    return (
      <div className="mt-6">
        <div
          className="text-[#272727]"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
    );
}

export default SingleBundleOthersDetail;

import About from "../../../Wrapper/About/About";
import HomePage from "../../../Wrapper/HomePage/HomePage";
import { SingleProductDeatilesContainer } from "../../../Wrapper/Shop/ShopProducts/SingleProductDetails/SingleProductDeatilesContainer";
import Login from "../../../components/pages/Authentications/Login";
import Signup from "../../../components/pages/Authentications/SignUp";
import Contact from "../../../components/pages/Contact/Contact";
import { Privacy } from "../../../components/pages/Privacy/Privacy";
import { ShopContainer } from "../../../components/pages/Shop/ShopContainer";

const shopPublicRoutes = [
  {
    id: 1,
    path: "/",
    element: <HomePage />,
  },
  {
    id: 2,
    path: "/login",
    element: <Login />,
  },
  {
    id: 3,
    path: "/signup",
    element: <Signup />,
  },
  {
    id: 5,
    path: "/about",
    element: <About />,
  },
  {
    id: 6,
    path: "/contact",
    element: <Contact />,
  },
  {
    id: 7,
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    id: 8,
    path: "/shopping",
    element: <ShopContainer />,
  },
  {
    id: 9,
    path: "/shop",
    element: <ShopContainer />,
  },
  {
    id: 10,
    path: "/shop/singleProduct/:id",
    element: <SingleProductDeatilesContainer />,
  },
];

export default shopPublicRoutes;

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import apiSlice from "../api/apiSlice";
import pageTitleSlice from "../features/PageTitle/pageTitleSlice";
import shopFilterSliceV3 from "../features/Shop/shopFilterSliceV3";
import shopSlice from "../features/Shop/shopSlice";
import billingSummaryPointSlice from "../features/checkout/billingSummaryPointSlice";
import billingSummarySlice from "../features/checkout/billingSummarySlice";
import checkoutDetailsSlice from "../features/checkout/checkoutDetailsSlice";
import userInfo from "../features/userInfo/userInfo";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whiteList: ["userInfo", "chat", "filter", "checkoutDetailes"],
};

const reducer = combineReducers({
    userInfo: userInfo,
    checkoutDetailes: checkoutDetailsSlice,
    pageTitle: pageTitleSlice,
    shop: shopSlice,
    billingSummary: billingSummarySlice,
    billingSummaryPoint: billingSummaryPointSlice,
    shopFilter: shopFilterSliceV3,
});
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        persistedReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export default store;

import { Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCategory, setSearchTerm, setStatePriceRange } from "../../../Redux/features/Shop/shopFilterSliceV3";
import { Modal } from "../../shared/Modal/Modal";
import MobileCategorySelector from "./Filter/MobileCategorySelector";
import MobilePriceRangeSelector from "./MobilePriceRangeSelector";

export const MobileFilterModal = ({ modalControll }) => {
    const { category, minPriceRange, maxPriceRange } = useSelector(state => state.persistedReducer.shopFilter);
    const [searchTerm, setTypeSearch] = useState("");
    const [currentCategory, setCurrentCategory] = useState(category);
    const [priceRange, setPriceRange] = useState([minPriceRange, maxPriceRange]);

    const dispatch = useDispatch();

    const filterSubmit = () => {
        // modalControll();

        // dispatch
        dispatch(setSearchTerm(searchTerm));
        dispatch(setCategory(currentCategory));
        dispatch(
            setStatePriceRange({
                min: priceRange[0],
                max: priceRange[1],
            })
        );

        modalControll();
    };
    return (
        <Modal modalControll={modalControll}>
            <Input placeholder="Search" className="w-full" value={searchTerm} onChange={e => setTypeSearch(e.target.value)} />
            <MobileCategorySelector {...{ currentCategory, setCurrentCategory }} />
            <MobilePriceRangeSelector {...{ priceRange, setPriceRange }} />
            <button
                className="bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] text-white my-[10px] w-full h-[40px] rounded-[4px]"
                onClick={filterSubmit}
            >
                Filter Now
            </button>
        </Modal>
    );
};

import React,{useState} from "react";
import ShopBundleCard from "./ShopBundleCard";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useGetAllBundlesQuery } from "../../../../Redux/features/bundle/bundleApi";
import GoPremium from "../../../shared/Packages/GoPremium";
import { useGetProfileDetailsWIthAuthQuery } from "../../../../Redux/features/userInfo/userApi";
const message =
  'You need to Update you package to buy a bundle. Please buy "Bundle" package to buy a bundle';
const BundleContainer = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const { data } = useGetAllBundlesQuery();
  const { data: profile } =
    useGetProfileDetailsWIthAuthQuery();
  

  return (
    <>
      <div className="max-w-[1200px] mx-auto my-8 p-4 bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]">
        <div className="hidden md:block w-full">
          <Swiper
            spaceBetween={1}
            slidesPerView={4}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="px-1"
          >
            {data?.data?.map((item) => (
              <SwiperSlide className="pb-8 px-4">
                <ShopBundleCard {...{ item, profile, setShowPopup }} key={item._id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" md:hidden w-full">
          <Swiper
            spaceBetween={1}
            slidesPerView={1}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="px-1"
          >
            {data?.data?.map((item) => (
              <SwiperSlide className="pb-8 px-4">
                <ShopBundleCard {...{ item, profile, setShowPopup }} key={item._id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={() => navigate("/bundle")}
            className={`special_profile_button`}
          >
            See All Bundle
          </button>
        </div>
      </div>
      {showPopup && <GoPremium {...{ setShowPopup, message }} />}
    </>
  );
};

export default BundleContainer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    billingSummaryWithPoint: {
        subTotal: 0,
        discount: 0,
        shipping: 20,
        tax: 0,
    },
};

const billingSummaryPointSlice = createSlice({
    name: "billingSummaryPoint",
    initialState,
    reducers: {
        setInitSubTotalWithPoint: (state = initialState, action) => {
            state.billingSummaryWithPoint.subTotal = action.payload;
        },
        increaseSubTotalWithPoint: (state = initialState, action) => {
            state.billingSummaryWithPoint.subTotal += action.payload;
        },
        decreaseSubTotalWithPoint: (state = initialState, action) => {
            state.billingSummaryWithPoint.subTotal -= action.payload;
        },
        setPointTax: (state = initialState, action) => {
            state.billingSummaryWithPoint.tax = action.payload;
        },
    },
});

export default billingSummaryPointSlice.reducer;
export const { setInitSubTotalWithPoint, increaseSubTotalWithPoint, decreaseSubTotalWithPoint, setPointTax } = billingSummaryPointSlice.actions;

import emptyProfile from "../../images/profile/emptyProfile.svg";

const customFunction = {
    truncateString: str => {
        if (str.length > 21) {
            return str.substring(0, 21) + "...";
        } else {
            return str;
        }
    },
    priceCalculator: (price, discount) => {
        if (!discount) {
            return price;
        } else {
            return Math.floor(price + (price * discount) / 100);
        }
    },
    discountCalculator: (price, discount) => {
        if (!discount) {
            return false;
        } else {
            return (price * discount) / 100;
        }
    },
    profilePhotoDecisionMaker: photoUrl => {
        if (photoUrl) {
            return photoUrl;
        } else {
            return emptyProfile;
        }
    },
};

export default customFunction;

import { MyAccount } from "../../components/pages/UserProfile/MyAccount/MyAccount";
import { OrderStatus } from "../../components/pages/UserProfile/OrderHistory/OrderStatus";
import { SingleSupportDetailes } from "../../components/pages/UserProfile/Support/SingleSupportDetailes/SingleSupportDetailesBody/SingleSupportDetailes";
import { SupportContainer } from "../../components/pages/UserProfile/Support/SupportContainer";
import { UserProfile } from "../../components/pages/UserProfile/UserProfile";
import { WalletContainer } from "../../components/pages/UserProfile/Wallet/WalletContainer";

const myAccountPrivateRoutes = [
  {
    id: 1,
    path: "/dashboard",
    element: <UserProfile />,
    nestedRoutes: [
      {
        id: 1,
        path: "account",
        element: <MyAccount />,
      },
      {
        id: 2,
        path: "my-orders",
        element: <OrderStatus />,
      },
      {
        id: 3,
        path: "my-wallet",
        element: <WalletContainer />,
      },
      {
        id: 4,
        path: "support",
        element: <SupportContainer />,
      },
      {
        id: 5,
        path: "support/:id",
        element: <SingleSupportDetailes />,
      },
    ],
  },
];

export default myAccountPrivateRoutes;
